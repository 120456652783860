import { Box, Button, Stack } from '@mui/material';
import { PayOrderDto, CreatePayOrderDto, UpdatePayOrderDto } from '@sr/dto';
import { FormDialog } from 'shared/ui/form-dialog';
import { PayOrderFormFields } from 'components/PayOrders/pay-order.form-fields';
import { notificationsConfig, PayOrdersList } from 'components/PayOrders/pay-orders-list';
import { formTools, FormData } from 'components/PayOrders/pay-order.form-tools';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useCreateOrEditDialog } from 'hooks/create-or-edit-dialog.hook';
import { usePayOrders } from 'hooks/payorders.hook';
import { useSnack } from 'shared/ui/snack';
import { fetchPayOrder } from 'shared/api/payorders';
import { useTitleHook } from 'hooks/page-title.hook';
import { Heading } from 'shared/ui/list-page-heading';
import { usePagingParams } from 'shared/hooks/paging-params';
import { useState } from 'react';
import { ButtonLink } from 'shared/ui/link-base';

const PayOrdersListPage = () => {
  useTitleHook('Бухгалтерия');
  const { showSuccessDelete, showFailedDelete } = useSnack(notificationsConfig);
  const showConfirm = useConfirmDialog();

  const [paging, setPaging] = usePagingParams();
  const [filterString, setFilterString] = useState('');

  const { collection, addItem, updateItem, removeItem } = usePayOrders(paging, filterString);

  const { dialogProps, openToAdd, openToEdit, itemToEdit } = useCreateOrEditDialog<PayOrderDto, CreatePayOrderDto, UpdatePayOrderDto, FormData>({
    addHandler: async (dto) => { await addItem(dto) },
    updateHandler: async (dto) => { await updateItem(dto) },
    formTools,
    notificationsConfig
  });

  const handleDelete = (payOrder: PayOrderDto) => {
    showConfirm({
      content: 'Удалить платежное поручение?'
    })
      .then(() => removeItem(payOrder.id))
      .then(() => showSuccessDelete())
      .catch(e => showFailedDelete(e));
  };

  const handleOpenForEdit = (payOrder: PayOrderDto) => {
    fetchPayOrder(payOrder.id)
      .then((item) => {
        openToEdit(item);
      });
  };

  return (
    <>
      <Heading
        title='Бухгалтерия'
        actions={
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button
              sx={{ mr: 1 }}
              variant='contained'
              onClick={openToAdd}
            >
              Внести платежное поручение
            </Button>

            <Stack spacing={1} direction='row'>
              <ButtonLink
                buttonProps={{ variant: 'contained' }}
                to="/accounting/import"
              >
                Импорт
              </ButtonLink>

              <ButtonLink
                buttonProps={{ variant: 'contained' }}
                to="/accounting/export"
              >
                Экспорт
              </ButtonLink>
            </Stack>
          </Box>

        } />

      <PayOrdersList
        collection={collection}
        paging={paging}
        onChangePaging={setPaging}
        filterString={filterString}
        onFilter={setFilterString}
        onEditClick={handleOpenForEdit}
        onDeleteClick={handleDelete} />

      <FormDialog<FormData>
        {...dialogProps}
        renderTitle={!itemToEdit ? 'Новое платежное поручение' : 'Платежное поручение'}
        renderForm={(props) => <PayOrderFormFields {...props} isNew={!itemToEdit} />}
      />
    </>
  );
};

export default PayOrdersListPage;
