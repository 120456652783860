import { Box, Checkbox, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { GridLayout, HeadCellsCollection } from 'shared/ui/grid-layout';
import { NotificationsConfig } from 'shared/ui/snack';
import { PayOrderDto, BA_TYPES, PayOrderGroup, PO_GROUPS, PayOrderStatus, POS_STATUSES, InvoiceDto, BankAccountDto, IPagingData } from '@sr/dto';
import { formatDate } from 'utils/date-format-helpers';
import PriceSpan from 'shared/ui/price-span';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { PayordersFilter } from 'features/payorders/payorders-filter';
import { Link } from 'shared/ui/link-base';
import React, { BaseSyntheticEvent } from 'react';


const columns = [
  'Номер ПП',
  'Дата',
  'Тип',
  'Группа',
  'Статус',
  'Сумма',
  'Счет',
  'Подробности',
];

export const notificationsConfig: NotificationsConfig = {
  itemTitleForm1: 'платежное поручение',
  itemTitleForm2: 'платежного поручения',
  gender: 'neutral'
};

type FilterableList = (
  | {  filterString: string, onFilter: (filterString: string) => void, }
  | {  filterString?: undefined, onFilter?: undefined }
)

type Props = {
  collection: IRemotePagedCollection<PayOrderDto>,
  paging: IPagingData,
  onChangePaging?: (paging: IPagingData) => void,
} & (
  | {  onRowSelect: SelectHandler, selectedRows: number[] }
  | {  onRowSelect?: undefined, selectedRows?: undefined }
) & EditableRowProps & FilterableList;

type SelectHandler = (rowId: number, value: boolean) => void

export const PayOrdersList = ({ collection, paging, onRowSelect, selectedRows, onChangePaging, filterString, onFilter, onEditClick, onDeleteClick }: Props) => {
  const gridColumns : HeadCellsCollection = onRowSelect
    ? [{
      label: <SelectOneOrAllCell
        onRowSelect={onRowSelect}
        isSelected={!!collection.collection.items.length && collection.collection.items.length === selectedRows?.length}
        ids={collection.collection.items.map(item=>item.id)}/> },
    ...columns]
    : columns;

  if (onEditClick && !gridColumns.includes('Действия')) {
    gridColumns.push('Действия');
  }

  return (
    <>
      {filterString !== undefined && onFilter &&
        <Box my={1}>
          <PayordersFilter filterString={filterString} onFilter={onFilter} />
        </Box>
      }
      <GridLayout
        columns={gridColumns}
        items={collection.collection.items}
        isLoading={collection.isLoading}
        error={collection.error}
        pagingData={paging}
        onChangePaging={onChangePaging}
        totalItemsCount={collection.collection.totalItemsCount}
        itemRender={payOrder => {
          let selectableProps: SelectableRowProps = { onRowSelect: undefined, isSelected: undefined };
          if (onRowSelect&&selectedRows){
            selectableProps = { onRowSelect: onRowSelect, isSelected: !!selectedRows.find(id=>id===payOrder.id) };
          }
          let editableProps: EditableRowProps = { onDeleteClick: undefined, onEditClick: undefined };
          if (onEditClick&&onDeleteClick) {
            editableProps = { onEditClick: onEditClick, onDeleteClick: onDeleteClick };
          }
          return <Row
            {...selectableProps}
            {...editableProps}
            key={payOrder.id}
            payOrder={payOrder}
          />;
        }}
        noItemsText="Список платежных поручений пуст" />
    </>
  );
};

type RowProps = {
  payOrder: PayOrderDto,
  onRowSelect?: SelectHandler;
} & SelectableRowProps & EditableRowProps;

type SelectableRowProps = (
  | { onRowSelect: SelectHandler; isSelected: boolean; }
  | { onRowSelect: undefined; isSelected: undefined; }
)

type EditableRowProps = (
  | {  onEditClick: (payOrder: PayOrderDto) => void, onDeleteClick: (payOrder: PayOrderDto) => void, }
  | {  onEditClick?: undefined, onDeleteClick?: undefined, }
);

const Row = ({ payOrder, onEditClick, onDeleteClick, onRowSelect, isSelected }: RowProps) => {
  return (
    <TableRow>
      { onRowSelect &&
        <TableCell >
          <SelectOneOrAllCell ids={[payOrder.id]} isSelected={isSelected} onRowSelect={onRowSelect}/>
        </TableCell>
      }
      <TableCell >
        <Link to={`/accounting/${payOrder.id}`}>{payOrder.documentNumber}</Link>
      </TableCell>
      <TableCell>
        {formatDate(payOrder.paymentDate)}
      </TableCell>
      <TableCell>
        {BA_TYPES[payOrder.bankAccount.type]}
      </TableCell>
      <TableCell>
        {PO_GROUPS[payOrder.group as PayOrderGroup]}
      </TableCell>
      <TableCell>
        {POS_STATUSES[payOrder.status as PayOrderStatus]}
      </TableCell>
      <TableCell>
        <PriceSpan price={payOrder.rows.reduce((acc, row) => acc + row.amount, 0)} size='small' />
      </TableCell>
      <TableCell>
        <BankAccountSpan bankAccount={payOrder.bankAccount} />
      </TableCell>
      <TableCell>
        <InvoicePayment payorder={payOrder} />
      </TableCell>
      {onEditClick &&
        <TableCell align="right" width={100}>
          <IconButton edge="end" onClick={() => onEditClick(payOrder)}>
            <EditIcon />
          </IconButton>
          <IconButton edge="end" onClick={() => onDeleteClick(payOrder)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      }
    </TableRow >
  );
};


export default PayOrdersList;


const InvoicePayment = ({ payorder }: { payorder: PayOrderDto; }) => {
  const invoice = payorder.rows[0]?.invoice;

  return (
    <>
      <Box>
        {invoice && <InvoiceInfo invoice={invoice} />}
      </Box>
      <Box>

      </Box>
      <Box>
        {/* Назначение платежа: &nbsp; */}
        <Typography component="span" variant='inherit' fontStyle='italic'>
          {payorder.description}
        </Typography>
      </Box>
    </>
  );
};


const BankAccountSpan = ({ bankAccount }: { bankAccount: BankAccountDto; }) => (
  <Typography variant='inherit' color={bankAccount.isActive ? 'inherit' : 'grey.500'}>
    {bankAccount.title}
  </Typography>
);

const InvoiceInfo = ({ invoice }: { invoice: InvoiceDto; }) => {
  return (
    <>
      <LinkToInvoice invoice={invoice} />&nbsp;
      для <LinkToLegalEntity legalEntity={invoice.buyer} /> (<LinkToCompany company={invoice.buyerCompany} />)
      от <LinkToLegalEntity legalEntity={invoice.seller} /> (<LinkToCompany company={invoice.sellerCompany} />)
    </>
  );
};

const SelectOneOrAllCell = ({ onRowSelect, isSelected, ids }: {onRowSelect: SelectHandler, isSelected: boolean, ids: number[]}) => {
  const handleCheckChange = (_: BaseSyntheticEvent, value: boolean) => {
    ids.forEach((id) => {
      onRowSelect(id, value);
    });
  };
  return(
    <Checkbox onChange={handleCheckChange} checked={isSelected}/>
  );
};
